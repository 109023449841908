import {
  BrokenCircuitError,
  circuitBreaker,
  CircuitState,
  ConsecutiveBreaker,
  DelegateBackoff,
  handleType,
  retry,
  wrap,
} from 'cockatiel';
import { RequestError } from 'halo-infinite-api';

const defaultCircuitOpen = 15000;
const circuitBreakerPolicy = circuitBreaker(
  handleType(RequestError, (err) => err.response.status === 429),
  {
    halfOpenAfter: new DelegateBackoff((ctx) => {
      if (
        ctx.result instanceof RequestError &&
        ctx.result.response.status === 429
      ) {
        const retryAfter = ctx.result.response.headers.get('retry-after');
        if (retryAfter) {
          return parseInt(retryAfter) * 1000;
        }
      }
      return defaultCircuitOpen;
    }),
    breaker: new ConsecutiveBreaker(1),
  }
);

let circuitNextHalfOpenTime: number | undefined;
circuitBreakerPolicy.onStateChange((state) => {
  if (state === CircuitState.Open) {
    circuitNextHalfOpenTime = Date.now() + 5000;
  } else {
    circuitNextHalfOpenTime = undefined;
  }
});

export const requestPolicy = wrap(
  retry(
    handleType(RequestError, (err) => err.response.status === 429).orType(
      BrokenCircuitError
    ),
    {
      backoff: new DelegateBackoff((context) => {
        if ('error' in context.result) {
          if (context.result.error instanceof RequestError) {
            const retryAfter =
              context.result.error.response.headers.get('retry-after');
            if (typeof retryAfter === 'string') {
              return parseInt(retryAfter) * 1000;
            }
            return 0;
          } else if (context.result.error instanceof BrokenCircuitError) {
            return circuitNextHalfOpenTime
              ? circuitNextHalfOpenTime - Date.now()
              : 0;
          }
        }

        throw new Error('Unexpected result type');
      }),
    }
  ),
  circuitBreakerPolicy,
  retry(
    handleType(
      RequestError,
      (err) =>
        err.response.status >= 500 ||
        err.response.status === 401 ||
        err.response.status === 0
    ).orType(
      TypeError,
      (err) =>
        err.message === 'NetworkError when attempting to fetch resource.' ||
        err.message === 'Failed to fetch' ||
        err.message === 'Load failed'
    ),
    {
      maxAttempts: 1,
      backoff: new DelegateBackoff((context) => {
        if (
          (context.result instanceof RequestError &&
            context.result.response.status === 0) ||
          context.result instanceof TypeError
        ) {
          // Add a little delay if the request failed due to a network error
          return 500;
        }
        return 0;
      }),
    }
  )
);
